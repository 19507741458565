<template>
<div>
  <v-sheet
      v-for="(reply , i) in replyParentQuestions" :key="i + 'reply'"
      class="pa-3 mb-2 d-flex justify-space-between align-center"
      style="border-radius: 8px"
      :style="replyBorderStyle(reply)"
  >

    <v-sheet width="100%" color="transparent">
      <v-row no-gutters>
        <v-col cols="6" class="">
          <h5>{{ reply.content_text }} </h5>
        </v-col>
        <v-col cols="6" class="pl-8">
          <h5>
            <span :style="reply.correct_match && !reply.correct ? 'text-decoration: line-through' : null">
              {{ getItem(reply.matched_reply).content_text }}
            </span>
            <span v-if="reply.correct_match && !reply.correct">
              ({{ getItem(reply.correct_match).content_text }})
            </span>
          </h5>
        </v-col>
      </v-row>
<!--      <h5>{{ reply.content_text }} - -->
<!--        <span :style="reply.correct_match && !reply.correct ? 'text-decoration: line-through' : null">-->
<!--        {{ getItem(reply.matched_reply).content_text }}-->
<!--      </span>-->
<!--        <span v-if="reply.correct_match && !reply.correct"> ({{ getItem(reply.correct_match).content_text }})</span>-->
<!--      </h5>-->
    </v-sheet>




    <v-btn v-if="!(preview || review) " @click="$emit('reset-answer' , reply)" small icon :color="wsACCENT">
      <v-icon>mdi-close</v-icon>
    </v-btn>

    <v-icon v-else-if="review" :color="reply.correct ? wsSUCCESS : wsWARNING">{{ getAnswerIcon(reply) }}</v-icon>

  </v-sheet>

  <v-row v-if="!review" class="mt-5" no-gutters>
    <template v-for="(item,i) in parentQuestions" >

      <v-col :key="i" cols="6" class="pr-2 pb-3">
        <v-sheet
            @click="handleParentClick(item)"
            :style="getBorderColor()"
            style="border-radius: 8px;transition: 0.3s all ease"
            class="pa-3 d-flex justify-space-between align-center  fill-height"
            :class="[{pointer : !preview && !review}]"
            :color="selectedElement === item.uuid ? wsBACKGROUND : null"

        >
          <h5>{{ item.content_text }}</h5>
        </v-sheet>
      </v-col>

      <v-col :key="i+'child'" cols="6" class="pl-2 pb-3">
        <v-sheet
            @click="handleChildClick(getChild(i))"
            :style="getBorderColor()"
            style="border-radius: 8px; transition: 0.3s all ease"
            class="pa-3 d-flex justify-space-between align-center  fill-height"
            :class="[{pointer : !preview && !review}]"
            :color="selectedElement ? wsLIGHTCARD : null"
        >
          <h5>
            {{ getChild(i).content_text  }}
          </h5>


        </v-sheet>
      </v-col>
    </template>

  </v-row>
</div>
</template>


<script>
export default {
  name: "matchedAnswersViewer",
  props : {
    question : {
      type : Object,
      default() { return {
          answers : []
        }
      }
    },
    preview : {
      type : Boolean,
      default : false
    },
    review : {
      type : Boolean,
      default : false
    }
  },
  data() {
    return {
      selectedElement : null
    }
  },
  computed : {
    replyParentQuestions() {
      return this.question.answers.filter(el => el.reply)
    },
    parentQuestions() {
      return this.question.answers.filter(el => el.is_match_parent && !el.reply)
    },
    childQuestions() {
      let matchedUuids = this.replyParentQuestions.map( el => el.matched_reply)
      return this.question.answers.filter(el => !el.is_match_parent && !matchedUuids.includes(el.uuid))
    }
  },
  methods : {
    getAnswerIcon(answer) {

      if ( this.review  ) {
        return answer.correct ? 'mdi-check-circle' : 'mdi-close-circle'
      }

      return 'mdi-radiobox-blank'
    },

    replyBorderStyle (reply) {
      let color = this.wsACCENT
      if (this.review && !this.preview) {
        color = reply.correct ? this.wsSUCCESS : this.wsWARNING
      }
      return `border : 1px solid ${color}`
    },

    getItem(uuid) {
      return this.question.answers.find(el => el.uuid === uuid) || {}
    },
    getChild(index){
      return this.childQuestions[index] ? this.childQuestions[index] : {}
    },
    handleChildClick(item) {
      if ( !this.selectedElement ) {
        return
      }
      let parentItem = this.question.answers.find(el => el.uuid === this.selectedElement)
      if (!parentItem) {
        return
      }
      let data = this.COPY(parentItem)
      data.matched_reply_answer_id = item.uuid
      this.$emit('answer', data)
      this.selectedElement = null
    },
    handleParentClick(item) {
      this.selectedElement = item.uuid
    },
    getBorderColor() {
      let borderColor = this.wsBACKGROUND
      return `border: 1px solid ${borderColor}`
    },
  }
}
</script>

<style scoped>

</style>